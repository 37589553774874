import ukrainianMessages from './../i18n/ua.js';
import englishMessages from './../i18n/en.js';
import russianMessages from './../i18n/ru.js';

const messages = {
  'en': englishMessages,
  'ua': ukrainianMessages,
  'ru': russianMessages
};

export default locale => messages[locale];