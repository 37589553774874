import React from 'react';
import { List, Datagrid, Edit, Create, SimpleForm, TextField, TextInput, BooleanField, BooleanInput, ReferenceArrayInput, SelectArrayInput, ReferenceArrayField, SingleFieldList, ChipField } from 'react-admin';
import BookIcon from '@material-ui/core/SvgIcon';

import nanoid from 'nanoid';

export const PostIcon = BookIcon;

const appsDefaultValue = {
  active: false,
  ttl: '1 hour',
  scope: ['config:own:ro', 'files:own:ro'],
};

const AppsTitle = ({ record }) => {
  return <span>App {record ? `"${record.app}"` : ''}</span>;
};

export const AppsList = props => (
  <List {...props}>
    <Datagrid rowClick="edit">
      <TextField source="id" />
      <TextField source="app" />
      <BooleanField source="active" />
      <ReferenceArrayField
        label="Calculators"
        reference="calculators"
        source="calculators"
      >
        <SingleFieldList linkType="show">
          <ChipField source="name" />
        </SingleFieldList>
      </ReferenceArrayField>
    </Datagrid>
  </List>
);

export const AppsEdit = props => (
  <Edit {...props} title={<AppsTitle/>}>
    <SimpleForm>
      <TextField source="app" />
      <TextInput source="secret" />
      <TextInput source="ttl" />
      <BooleanInput source="active" />
      <ReferenceArrayInput source="calculators" reference="calculators" allowEmpty>
        <SelectArrayInput optionText="name" />
      </ReferenceArrayInput>
    </SimpleForm>
  </Edit>
);

export const AppsCreate = (props) => (
  <Create title="Create an Application" {...props}>
    <SimpleForm defaultValue={appsDefaultValue}>
      <TextInput source="app" />
      <TextInput source="secret" defaultValue={() => nanoid()}/>
      <TextInput source="ttl" />
      <BooleanInput source="active" />
      <ReferenceArrayInput source="calculators" reference="calculators" allowEmpty>
        <SelectArrayInput optionText="name" />
      </ReferenceArrayInput>
    </SimpleForm>
  </Create>
);