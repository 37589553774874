import { AUTH_LOGIN, AUTH_LOGOUT, AUTH_ERROR, AUTH_CHECK, AUTH_GET_PERMISSIONS } from 'react-admin';

let userData = null;
export default (type, params) => {
  // called when the user attempts to log in
  if (type === AUTH_LOGIN) {
    const { username, password } = params;
    const request = new Request(process.env.REACT_APP_CONFIG_SERVER_HOST +'/auth/user', {
      method: 'POST',
      body: JSON.stringify({ username, password }),
      headers: new Headers({ 'Content-Type': 'application/json' }),
    })
    return fetch(request)
      .then(response => {
        if (response.status < 200 || response.status >= 300) {
          throw new Error(response.statusText);
        }
        return response.json();
      })
      .then((authData) => {
        localStorage.setItem('token', JSON.stringify(authData));
        userData = authData;
      });
  }
  // called when the user clicks on the logout button
  if (type === AUTH_LOGOUT) {
    localStorage.removeItem('token');
    userData = null;
    return Promise.resolve();
  }
  // called when the API returns an error
  if (type === AUTH_ERROR) {
    const { status } = params;
    if (status === 401 || status === 403) {
      localStorage.removeItem('token');
      userData = null;
      return Promise.reject();
    }
    return Promise.resolve();
  }
  // called when the user navigates to a new location
  if (type === AUTH_CHECK) {
    return new Promise((resolve, reject) => {
      const token = localStorage.getItem('token');
      if (token && !userData) {
        userData = JSON.parse(token)
      }

      const now = new Date();
      if (!userData.expire || now > userData.expire) {
        const request = new Request(process.env.REACT_APP_CONFIG_SERVER_HOST + '/auth/extend', {
          method: 'POST',
          // body: JSON.stringify({ username, password }),
          headers: new Headers({
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + userData.jwt,
          }),
        })
        fetch(request)
          .then(response => {
            if (response.status < 200 || response.status >= 300) {
              return reject(new Error(response.statusText));
            }
            return response.json();
          })
          .then((authData) => {
            localStorage.setItem('token', JSON.stringify(authData));
            userData = authData;
            resolve();

            userData.expire = new Date();
            userData.expire.setMinutes(userData.expire.getMinutes() + 1);
          });
      }
      else {
        token ? resolve() : reject();
      }
    });
  }
  if (type === AUTH_GET_PERMISSIONS) {
    const token = localStorage.getItem('token');
    if (token && !userData) {
      userData = JSON.parse(token)
    }
    return Promise.resolve(userData ? userData.scope : ["dummy"]);
  }
  return Promise.reject('Unknown method');
};
