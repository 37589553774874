import React from 'react';
import { SelectInput, FormDataConsumer, ArrayInput, SimpleFormIterator, TextInput } from 'react-admin';

class GoalSeekMacros extends React.Component {
  render () {
    return <div>
      <div id={"setField"}><TextInput source={this.props.getSource("setField")}/></div>
      <div id={"goal"}><TextInput source={this.props.getSource("goal")}/></div>
      <div id={"changeField"}><TextInput source={this.props.getSource("changeField")}/></div>
      <div id={"guess"}><TextInput source={this.props.getSource("guess")}/></div>
      <div id={"epsilon"}><TextInput source={this.props.getSource("epsilon")}/></div>
    </div>;
  }
}

const selectTypes = [
  {id: 'goalseek', name: 'Goal seek'}
];

const macrosTypes = {
  goalseek: GoalSeekMacros,
};

export default class MacrosInput extends React.Component {
  render () {
    return <ArrayInput source={this.props.source}>
      <SimpleFormIterator>
        <SelectInput source={"type"} choices={selectTypes}/>
        <FormDataConsumer>
          {(props) =>
            props.scopedFormData && props.scopedFormData.type && macrosTypes[props.scopedFormData.type] ? (() => {
              const Component = macrosTypes[props.scopedFormData.type];
              return <Component key={props.getSource("key")} {...props} />
            })() : null
          }
        </FormDataConsumer>
      </SimpleFormIterator>
    </ArrayInput>;
  }
}
