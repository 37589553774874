const convertFileToBase64 = file => new Promise((resolve, reject) => {
  const reader = new FileReader();
  reader.readAsDataURL(file.rawFile);

  reader.onload = () => resolve(reader.result);
  reader.onerror = reject;
});

const uploadFeature = requestHandler => (type, resource, params) => {
  if (type === 'UPDATE' && resource === 'calculators-file') {
    // notice that following condition can be true only when `<ImageInput source="pictures" />` component has parameter `multiple={true}`
    // if parameter `multiple` is false, then data.pictures is not an array, but single object
    if (params.data.file && params.data.file.rawFile instanceof File) {
      return convertFileToBase64(params.data.file)
        .then(base64Pictures => {
          return requestHandler(type, resource, {
            ...params,
            data: {
              ...params.data,
              file: {
                src: base64Pictures,
                title: `${params.data.file.title}`,
              }
            }
          });
        });
    }
  }
  // for other request types and resources, fall back to the default request handler
  return requestHandler(type, resource, params);
};

export default uploadFeature;