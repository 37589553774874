import React from 'react';
import { List, Edit, Create, Datagrid, TextField, EmailField, BooleanField, SimpleForm, TextInput, BooleanInput, DisabledInput, SelectInput } from 'react-admin';

const roles = [
  { id: 'viewer', name: 'Viewer' },
  { id: 'admin', name: 'Admin' },
];

export const UsersList = props => (
  <List {...props}>
    <Datagrid rowClick="edit">
      <TextField source="id" />
      <EmailField source="email" />
      <TextField source="name" />
      <TextField source="role" />
      <BooleanField source="active" />
    </Datagrid>
  </List>
);

export const UserCreate = props => (
  <Create {...props}>
    <SimpleForm>
      <TextInput source="email" />
      <TextInput source="name" />
      <TextInput source="password" type="password" />
      <SelectInput label="Role" source="role" choices={roles} />
      <BooleanInput source="active" />
    </SimpleForm>
  </Create>
);

export const UserEdit = props => (
  <Edit {...props}>
    <SimpleForm>
      <DisabledInput source="id" />
      <TextInput source="email" />
      <TextInput source="name" />
      <SelectInput label="Role" source="role" choices={roles} />
      <BooleanInput source="active" />
    </SimpleForm>
  </Edit>
);
