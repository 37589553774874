import { Edit, TabbedForm, Button, FormTab, Toolbar, SaveButton } from 'react-admin';
import OutputInput from './OutputInput';
import { Link, Router } from 'react-router-dom';
import OutputIcon from '@material-ui/icons/Launch';

import { createHashHistory } from 'history';
import React from 'react';
const history = createHashHistory();

export const EditOutputButton = ({record}) => (
  <Router history={history}>
    <Button
      component={Link}
      to={`/calculators-output/${record.id}`}
      disabled={!record.outputTabVisible}
      label={null}
      title={null}
    >
      <OutputIcon/>
    </Button>
  </Router>
);

const FormToolbar = props => (
  <Toolbar {...props} >
    <SaveButton/>
  </Toolbar>
);

class OutputTabbedForm extends React.Component {
  render() {
    const tabProps = this.props;
    return <TabbedForm {...tabProps} toolbar={<FormToolbar />} redirect={'/calculators'}>
      {
        Object.keys(tabProps.record.output).map(v =>
          <FormTab label={v} key={"tab" + v}>
            <OutputInput source={"output." + v}/>
          </FormTab>
        )
      }
    </TabbedForm>
  }
}

export const CalculatorsOutputEdit = (props) => (
  <Edit {...props}>
    <OutputTabbedForm />
  </Edit>
);
