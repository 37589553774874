import React from 'react';
import { List, Datagrid, Create, Edit, Button, CheckboxGroupInput, TabbedForm, FormTab, TextField, DisabledInput, TextInput, ArrayInput, SimpleForm, SimpleFormIterator } from 'react-admin';
import MacrosInput from './MacrosInput';
import MappingInput from './MappingInput';
import { EditInputButton } from './calculators-input';
import { EditOutputButton } from './calculators-output';
import { EditFileButton } from './calculators-file';
import { Router } from 'react-router';
import { Link } from 'react-router-dom';
import EditIcon from '@material-ui/icons/Edit';
import { createHashHistory } from 'history';

const history = createHashHistory();
const EditButton = ({record}) => (
  <Router history={history}>
    <Button
      component={Link}
      to={`/calculators/${record.id}`}
      disabled={!record.file}
      label={null}
      title={null}
    >
      <EditIcon/>
    </Button>
  </Router>
);

export const CalculatorsList = props => (
  <List {...props}>
    <Datagrid>
      <TextField source="id" />
      <TextField source="name" />
      <TextField source="file" />
      <TextField source="path" />
      <EditButton/>
      <EditFileButton />
      <EditInputButton />
      <EditOutputButton />
    </Datagrid>
  </List>
);

export const CalculatorsEdit = (props) => (
  <Edit {...props}>
    <TabbedForm>
      <FormTab label={"summary"}>
        <DisabledInput source="id" />
        <TextInput source="name" />
        <TextInput source="path" />
      </FormTab>
      <FormTab label={"processsing"}>
        <SheetCheckboxesInput/>
      </FormTab>
      <FormTab label={"sheets"}>
        <MappingInput source={"sheets"} />
      </FormTab>
      <FormTab label={"macros"}>
        <MacrosInput source={"macros"}/>
      </FormTab>
      <FormTab label={"output"}>
        <ArrayInput source={"outputSets"}>
          <SimpleFormIterator>
            <TextInput source={"name"} />
          </SimpleFormIterator>
        </ArrayInput>
      </FormTab>
    </TabbedForm>
  </Edit>
);

export const CalculatorsCreate = (props) => (
  <Create {...props}>
    <SimpleForm redirect={(basePath, id, data) => `/calculators-file/${data.id}`}>
      <TextInput source="name" />
      <TextInput source="path" />
    </SimpleForm>
  </Create>
);

const SheetCheckboxesInput = (props) => (
  <CheckboxGroupInput className={"checkboxGroup"} source="processSheets" choices={
      props.record && props.record.xlsx ?
        props.record.xlsx.sheets.map(c => {return {id: c, name: c}}) : []
    } translateChoice={false}/>
);
