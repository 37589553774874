import ukrainianMessages from 'ra-language-ukrainian';
import { mergeTranslations } from 'react-admin';

const messages = {
  menu: {
    apps: "Додатки",
    calculators: "Калькулятори",
    users: "Користувачі",
    partners: "Партнери",
    requests: "Запити"
  },
  resources: {
    apps: {
      name: "Додатки",
      fields: {
        active: "Активний",
        scope: "Дозволи",
        calculators: "Калькулятори"
      }
    },
    calculators: {
      name: "Калькулятори",
      macros: {
        name: "Макрос"
      },
      fields: {
        name: "Назва",
        file: "Назва файлу",
        macros: "Макрос"
      }
    },
    partners: {
      name: "Партнери",
      fields: {
        name: "Назва",
        calculatorsList: "Калькулятори"
      }
    },
    users: {
      name: "Користувачі"
    },
    input: {
      name: "Test",
      fields: {
        name: "TEst"
      }
    },
    macros: {
      name: "Макрос"
    }
  },
  ...mergeTranslations(ukrainianMessages),
};

export default messages;