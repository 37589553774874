import FileIcon from '@material-ui/icons/AttachFile';
import React from 'react';
import { Edit, SimpleForm, Button, FileInput, FileField, Toolbar, SaveButton } from 'react-admin';
import { Link, Router } from 'react-router-dom';
import { createHashHistory } from 'history';
const history = createHashHistory();

export const EditFileButton = ({record}) => (
  <Router history={history}>
    <Button
      component={Link}
      to={`/calculators-file/${record.id}`}
      label={null}
      title={null}
    >
      <FileIcon/>
    </Button>
  </Router>
);

const FormToolbar = props => (
  <Toolbar {...props} >
    <SaveButton/>
  </Toolbar>
);


export const CalculatorsFileEdit = (props) => (
  <Edit {...props}>
    <SimpleForm redirect={'/calculators'} toolbar={<FormToolbar />}>
      {/*<TextInput source={"file"}/>*/}
      <FileInput source={"file"} label="Related files" accept="application/vnd.ms-excel,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet,application/vnd.ms-excel.sheet.macroEnabled.12,application/vnd.ms-excel.sheet.macroenabled.12">
        <FileField source="url" title="title" />
      </FileInput>
    </SimpleForm>
  </Edit>
);
