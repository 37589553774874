import './index.css';
import * as serviceWorker from './serviceWorker';

import React from 'react';
import ReactDOM from 'react-dom';
import { Admin, Resource } from 'react-admin';
import restProvider from 'ra-data-simple-rest';
import UserIcon from '@material-ui/icons/Group';
import PeopleIcon from '@material-ui/icons/People';
import i18nProvider from './Components/i18nProvider';
import JssProvider from 'react-jss/lib/JssProvider';

import { UsersList, UserEdit, UserCreate } from './Components/users'
import authProvider from './Components/authProvider'
import NotFound from './Components/NotFound'
import CustomLayout from './Components/CustomLayout'
import { AppsCreate, AppsEdit, AppsList, PostIcon } from './Components/apps'
import { RequestList, RequestShow } from './Components/request'
import { CalculatorsEdit, CalculatorsList, CalculatorsCreate } from './Components/calculators'
import { CalculatorsInputEdit } from './Components/calculators-input'
import { CalculatorsOutputEdit } from './Components/calculators-output'
import { CalculatorsFileEdit } from './Components/calculators-file'
import uploadFeature from './features/uploadFeature';
import { PartnerCreate, PartnerEdit, PartnerList } from './Components/partners'
import { PartnerInputEdit } from './Components/partners-input'
import { generateClassName } from './Components/classResolver'

const dataProvider = restProvider(process.env.REACT_APP_CONFIG_SERVER_HOST);
const uploadCapableDataProvider = uploadFeature(dataProvider);

ReactDOM.render(
  <JssProvider generateClassName={generateClassName}>
      <Admin
        appLayout={CustomLayout}
        dataProvider={uploadCapableDataProvider}
        authProvider={authProvider}
        i18nProvider={i18nProvider}
        locale="ua"
        title="VAB leasing"
        catchAll={NotFound}
      >
        {permissions => [
          <Resource name="apps" list={permissions.indexOf('apps:view') !== -1 ? AppsList : null} edit={permissions.indexOf('apps:edit') !== -1 ? AppsEdit: null} create={permissions.indexOf('apps:edit') !== -1 ? AppsCreate : null} icon={PostIcon}/>,
          <Resource name="calculators" list={permissions.indexOf('calculators:view') !== -1 ? CalculatorsList : null} edit={permissions.indexOf('calculators:edit') !== -1 ? CalculatorsEdit : null} create={permissions.indexOf('calculators:view') !== -1 ? CalculatorsCreate : null}/>,
          <Resource name="calculators-input" edit={permissions.indexOf('calculators:edit') !== -1 ? CalculatorsInputEdit : null} icon={AppsCreate}/>,
          <Resource name="calculators-output" edit={permissions.indexOf('calculators:edit') !== -1 ? CalculatorsOutputEdit : null} icon={AppsCreate}/>,
          <Resource name="calculators-file" edit={permissions.indexOf('calculators:edit') !== -1 ? CalculatorsFileEdit : null} icon={AppsCreate}/>,
          <Resource name="partners" list={permissions.indexOf('partners:view') !== -1 ? PartnerList : null} edit={permissions.indexOf('partners:edit') !== -1 ? PartnerEdit : null} create={permissions.indexOf('partners:edit') !== -1 ? PartnerCreate : null} icon={PeopleIcon}/>,
          <Resource name="partners-input" edit={permissions.indexOf('partners:edit') !== -1 ? PartnerInputEdit : null} icon={PeopleIcon}/>,
          <Resource name="users" list={permissions.indexOf('users:view') !== -1 ? UsersList : null} create={permissions.indexOf('users:edit') !== -1 ? UserCreate : null} edit={permissions.indexOf('users:edit') !== -1 ? UserEdit : null} icon={UserIcon}/>,
          <Resource name="request" list={permissions.indexOf('requests:view') !== -1 ? RequestList : null} show={permissions.indexOf('requests:view') !== -1 ? RequestShow : null}/>,
        ]}
      </Admin>
  </JssProvider>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
