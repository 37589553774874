import React from 'react';
import { Edit, TabbedForm, Button, FormTab, Toolbar, SaveButton, BooleanInput, TextInput, FormDataConsumer } from 'react-admin';
import { Link, Router } from 'react-router-dom';
import InputIcon from '@material-ui/icons/Input';

import { createHashHistory } from 'history';

const history = createHashHistory();

export const EditInputButton = ({record}) => (
  <Router history={history}>
    <Button
      component={Link}
      to={`/partners-input/${record.id}`}
      disabled={!record.calculatorsList || !record.calculatorsList.length}
      label={null}
      title={null}
    >
      <InputIcon/>
    </Button>
  </Router>
);

const FormToolbar = props => (
  <Toolbar {...props} >
    <SaveButton/>
  </Toolbar>
);

class InputTabbedForm extends React.Component {
  render () {
    const tabProps = this.props;
    return <TabbedForm {...tabProps} toolbar={<FormToolbar/>}>
      {
        Object.keys(tabProps.record.calculators).map(vid => {
            const v = tabProps.record.calculators[vid];
            return <FormTab label={v.name} key={'tab' + vid}>
              <CheckboxesInput source={vid}/>
            </FormTab>;
          }
        )
      }
    </TabbedForm>;
  }
}

export const PartnerInputEdit = (props) => (
  <Edit {...props}>
    <InputTabbedForm redirect={'/partners'} />
  </Edit>
);

const CheckboxesInput = (props) => (
  Object.keys(props.record.calculators[props.source].fields).map(field => {
    const prefix = 'calculators.' + props.source + '.fields.' + field + '.';
    return <div key={field}>
      <BooleanInput label={field} source={prefix + 'enabled'}/>
      <FormDataConsumer>
        {({ formData, ...rest }) => formData.calculators[props.source].fields[field].enabled &&
          [
            <TextInput source={prefix + 'default'} label={'Default value'} {...rest} key={"default"}/>,<br key={"break"}/>,
            <TextInput source={prefix + 'allowed'} label={'Allowed values'} {...rest} key={"allowed"}/>
          ]
        }
      </FormDataConsumer>
    </div>;
  })
);
