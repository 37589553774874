import React from 'react'
import { List, Show, Datagrid, Button, TextField, BooleanField, DateField, SimpleShowLayout, ShowButton, CardActions, ListButton, Filter, TextInput, BooleanInput } from 'react-admin'
import { withStyles } from '@material-ui/core/styles';
import GetApp from '@material-ui/icons/GetApp';

const RequestActions = ({ basePath, data, resource }) => (
  <CardActions>
    <ListButton basePath={basePath} />
  </CardActions>
);

const RequestTitle = ({ record }) => {
  return <span>Request {record ? `"${record.key}"` : ''}</span>;
};

const RequestFilter = (props) => (
  <Filter {...props}>
    <TextInput label="Key" source="key" alwaysOn />
    <BooleanInput label="Success" source="success" defaultValue={true} />
    <TextInput label="Partner" source="partnerName" defaultValue="" />
    <TextInput label="Calculator" source="calculatorName" defaultValue="" />
  </Filter>
);

const handleDownload = (record) => {
  console.log(record);
  window.location = process.env.REACT_APP_CONFIG_SERVER_HOST + '/request/' + record.id + '/file';
};

const DownloadButton = ({record}) => (
  <Button
    onClick={() => handleDownload(record)}
    label={'Download'}
    title={'Download'}
  >
    <GetApp/>
  </Button>
);


export const RequestList = props => (
  <List {...props} filters={<RequestFilter />}>
    <Datagrid rowClick="edit">
      <TextField source="key" />
      <BooleanField source="success" />
      <TextField source="partner" />
      <TextField source="calculator" />
      <DateField source="time" showTime locales="uk-UA"  />
      <ShowButton />
      <DownloadButton />
    </Datagrid>
  </List>
);

const styles = {
  code: {
    whiteSpace: 'pre',
    fontFamily: 'monospace !important',
  }
};

const CodeField = withStyles(styles)(({ classes, ...props }) => (
  <TextField className={classes.code} {...props} />
));

export const RequestShow = (props) => (
  <Show {...props} title={<RequestTitle/>} actions={<RequestActions />}>
    <SimpleShowLayout>
      <TextField source="key" />
      <BooleanField source="success" />
      <TextField source="calculator" />
      <TextField source="calculator_file" />
      <TextField source="file" />
      <TextField source="partner" />
      <DateField source="time" showTime locales="uk-UA"  />
      <CodeField addLabel={true} label="Errors" source="errors" />
      <CodeField addLabel={true} label="Data" source="data" />
    </SimpleShowLayout>
  </Show>
);