import React from 'react';
import { SelectInput, FormDataConsumer, ArrayInput, SimpleFormIterator, TextInput } from 'react-admin';


class BaseType extends React.Component {
  render() {
    return <TextInput source={this.props.getSource("cell")} />;
  }
}
class MapType extends BaseType {
  render () {
    return <div>
      <TextInput source={this.props.getSource("cell")}/>
      <ArrayInput source={this.props.getSource("map")}>
        <SimpleFormIterator>
          <TextInput source={"key"}/>
          <TextInput source={"value"}/>
        </SimpleFormIterator>
      </ArrayInput>
    </div>;
  }
}

class TableType extends BaseType {
  render () {
    return <div>
      <TextInput source={this.props.getSource("sheet")}/>
      <TextInput source={this.props.getSource("rowRange")}/>
      <ArrayInput source={this.props.getSource("structure")}>
        <SimpleFormIterator>
          <TextInput source={"name"}/>
          <TextInput source={"column"}/>
          <SelectInput source={"type"} choices={primitiveTypeOptions}/>
        </SimpleFormIterator>
      </ArrayInput>
    </div>;
  }
}

const primitiveTypeOptions = [
  {id: 'int', name: 'Integer'},
  {id: 'float', name: 'Decimal'},
  {id: 'pos_int', 'name': 'Positive integer'},
  {id: 'pos_float', 'name': 'Positive decimal'},
  {id: 'date', name: 'Date'},
  {id: 'string', name: 'Text'},
];
const typeOptions = [
  {id: 'int', name: 'Integer'},
  {id: 'float', name: 'Decimal'},
  {id: 'pos_int', 'name': 'Positive integer'},
  {id: 'pos_float', 'name': 'Positive decimal'},
  {id: 'date', name: 'Date'},
  {id: 'string', name: 'Text'},
  {id: 'map', name: 'Mapping'},
  {id: 'table', name: 'Table'},
];

const inputTypes = {
  map: MapType,
  table: TableType
};

class InputForm extends React.Component {
  constructor (props) {
    super(props);
    this.type = null
  }

  shouldComponentUpdate (nextProps) {
    return nextProps && nextProps.scopedFormData && nextProps.scopedFormData.type !== this.type;
  }
  render () {
    this.type = this.props.scopedFormData.type;
    if (this.props.scopedFormData && this.props.scopedFormData.type && inputTypes[this.props.scopedFormData.type]) {
      const Component = inputTypes[this.props.scopedFormData.type];
      return <Component key={this.props.getSource("key")} {...this.props} />
    }
    return <BaseType key={this.props.getSource("key")} {...this.props} />
  }
}

export default class InputInput extends React.Component {
  render () {
    return <ArrayInput source={this.props.source}>
      <SimpleFormIterator>
        <TextInput source={"name"} />
        <SelectInput source={"type"} choices={typeOptions} />
        <FormDataConsumer>
          {(props) => <InputForm key={props.getSource("key")} {...props} />}
        </FormDataConsumer>
      </SimpleFormIterator>
    </ArrayInput>;
  }
}
