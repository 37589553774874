import InputIcon from '@material-ui/icons/Input';
import React from 'react';
import { Edit, SimpleForm, Button, Toolbar, SaveButton } from 'react-admin';
import { Link, Router } from 'react-router-dom';
import { createHashHistory } from 'history';
import InputInput from './InputInput';
const history = createHashHistory();

export const EditInputButton = ({record}) => (
  <Router history={history}>
    <Button
      component={Link}
      to={`/calculators-input/${record.id}`}
      disabled={!record.file}
      label={null}
      title={null}
    >
      <InputIcon/>
    </Button>
  </Router>
);


const FormToolbar = props => (
  <Toolbar {...props} >
    <SaveButton/>
  </Toolbar>
);


export const CalculatorsInputEdit = (props) => (
  <Edit {...props}>
    <SimpleForm toolbar={<FormToolbar/>} redirect={'/calculators'}>
      <InputInput source={"input"}/>
    </SimpleForm>
  </Edit>
);
