import React from 'react';
import { MenuItemLink, translate, WithPermissions } from 'react-admin';
import UserIcon from '@material-ui/icons/Group';
import PostIcon from '@material-ui/icons/Book';
import PeopleIcon from '@material-ui/icons/PeopleOutline';
import LayersIcon from '@material-ui/icons/Layers';

const CustomMenu = ({translate, ...props}) => (
  <div>
    <WithPermissions render={({permissions}) => (
      permissions && permissions.indexOf('apps:view') !== -1 ?
        <MenuItemLink
          to={{
            pathname: '/apps',
          }}
          primaryText={translate('menu.apps')}
          leftIcon={<PostIcon/>}
        /> : null
    )}/>
    <WithPermissions render={({permissions}) => (
      permissions && permissions.indexOf('calculators:view') !== -1 ?
        <MenuItemLink
          to={{
            pathname: '/calculators',
          }}
          primaryText={translate('menu.calculators')}
          leftIcon={<PostIcon/>}
        /> : null
    )}/>
    <WithPermissions render={({permissions}) => (
      permissions && permissions.indexOf('partners:view') !== -1 ?
        <MenuItemLink
          to="/partners"
          primaryText={translate('menu.partners')}
          leftIcon={<PeopleIcon/>}
        /> : null
    )}/>
    <WithPermissions render={({permissions}) => (
      permissions && permissions.indexOf('users:view') !== -1 ?
        <MenuItemLink
          to="/users"
          primaryText={translate('menu.users')}
          leftIcon={<UserIcon/>}
        /> : null
    )}/>
    <WithPermissions render={({permissions}) => (
      permissions && permissions.indexOf('requests:view') !== -1 ?
        <MenuItemLink
          to="/request"
          primaryText={translate('menu.requests')}
          leftIcon={<LayersIcon/>}
        /> : null
    )}/>
  </div>
);

export default translate(CustomMenu);