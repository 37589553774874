import React from 'react';
import { DisabledInput, ArrayInput, SimpleFormIterator, TextInput } from 'react-admin';

export default class MappingInput extends React.Component {
  render () {
    return <ArrayInput source={this.props.source}>
        <SimpleFormIterator disableRemove disableAdd>
          <DisabledInput source={"sheet"}/>
          <TextInput source={"alias"} />
        </SimpleFormIterator>
    </ArrayInput>;
  }
}
