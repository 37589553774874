import React from 'react'
import { Create, Edit, List, Button, Datagrid, SimpleForm, TextField, TextInput, ReferenceArrayInput, SelectArrayInput, ReferenceArrayField, SingleFieldList, ChipField, BooleanInput } from 'react-admin'
import nanoid from 'nanoid'
import { Router } from 'react-router'
import { Link } from 'react-router-dom'
import EditIcon from '@material-ui/icons/Edit';
import { createHashHistory } from 'history';
import { EditInputButton } from './partners-input';

const PartnerTitle = ({ record }) => {
  return <span>Partners {record ? `"${record.name}"` : ''}</span>;
};

const history = createHashHistory();
const EditButton = ({record}) => (
  <Router history={history}>
    <Button
      component={Link}
      to={`/partners/${record.id}`}
      label={null}
      title={null}
    >
      <EditIcon/>
    </Button>
  </Router>
);

export const PartnerList = props => (
  <List {...props}>
    <Datagrid>
      <TextField source="id" />
      <TextField source="name" />
      <ReferenceArrayField
        label="Calculators"
        reference="calculators"
        source="calculatorsList"
      >
        <SingleFieldList linkType="show">
          <ChipField source="name" />
        </SingleFieldList>
      </ReferenceArrayField>
      <EditButton/>
      <EditInputButton/>
    </Datagrid>
  </List>
);

export const PartnerCreate = (props) => (
  <Create title="Create a Partner" {...props}>
    <SimpleForm>
      <TextInput source="name" />
      <TextInput source="secret" defaultValue={() => nanoid()}/>
      <ReferenceArrayInput source="calculatorsList" reference="calculators" allowEmpty>
        <SelectArrayInput optionText="name" />
      </ReferenceArrayInput>
      <BooleanInput source="master"/>
    </SimpleForm>
  </Create>
);

export const PartnerEdit = props => (
  <Edit {...props} title={<PartnerTitle/>}>
    <SimpleForm>
      <TextInput source="id" disabled/>
      <TextInput source="name" />
      <TextInput source="secret" />
      <ReferenceArrayInput source="calculatorsList" reference="calculators" allowEmpty>
        <SelectArrayInput optionText="name"/>
      </ReferenceArrayInput>
      <BooleanInput source="master"/>
    </SimpleForm>
  </Edit>
);